import { hashString } from "../components/hash-string";

let tiktok: any;

if (typeof window !== "undefined") {
  tiktok = (window as any)?.ttq;
}

async function tiktokIdentify(
  email?: string,
  phoneNumber?: string,
  externalId?: string
) {
  const hashedEmail = await hashString(email);
  const hashedPhoneNumber = await hashString(phoneNumber);
  const hashedExternalId = await hashString(externalId);

  tiktok?.identify({
    email: hashedEmail,
    phone_number: hashedPhoneNumber,
    external_id: hashedExternalId,
  });
}
// https://ads.tiktok.com/help/article/standard-events-parameters?lang=en
export async function trackTiktok(
  eventName:
    | "InitiateCheckout"
    | "Subscribe"
    | "InitiateSignup"
    | "InitiateOrganizationSignup"
    | "SubscribeOrganization",
  props: {
    productId?: string;
    productName?: string;
    value?: number;
    email?: string;
    phoneNumber?: string;
    externalId?: string;
  }
) {
  if (!tiktok) {
    return;
  }

  await tiktokIdentify(props.email, props.phoneNumber, props.externalId);

  tiktok?.track(eventName, {
    contents: [
      {
        content_id: props.productId,
        content_type: "subscription",
        content_name: props.productName,
      },
    ],
    value: props.value?.toString(),
    currency: "USD",
  });
}

import { Box, Progress, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";

interface Props {
  step: number;
  label1: string;
  label2: string;
  label3: string;
  intervalTime?: number;
}

export default function ProgressLoader(props: Props) {
  const [loadingProgress, setLoadingProgress] = useState(5);

  useEffect(() => {
    const loadingProgressInterval = setInterval(() => {
      setLoadingProgress((p) => (p < 100 - props.step ? p + props.step : p));
    }, props.intervalTime || 250);

    return () => {
      clearInterval(loadingProgressInterval);
    };
  }, [props.step, props.intervalTime]);

  return (
    <Box py={5} mx="auto" maxW="100%" w={"400px"}>
      <Progress height="25px" hasStripe value={loadingProgress} />
      <Text mt={2} color="brand.gray.400" textAlign="center" fontSize="xs">
        {loadingProgress < 33 && props.label1}
        {loadingProgress >= 33 && loadingProgress < 66 && props.label2}
        {loadingProgress >= 66 && props.label3}
      </Text>
    </Box>
  );
}
4;

export const promotionCodes =
  process.env.NEXT_PUBLIC_ENVIRONMENT === "development"
    ? [
        {
          name: "Campaign40",
          percentage: 40,
          code: "promo_1OvQ9tC5cLI7zqt3bLwiA2Bp", // LOCALHOST
        },
      ]
    : [
        {
          name: "Campaign40",
          percentage: 40,
          code: "promo_1PHs9wC5cLI7zqt3MC0214Ja",
        },
      ];
